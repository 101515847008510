<template>
  <b-modal
    id="modal-parley-detail-report"
    ok-variant="outline-secondary"
    :ok-title="$t('button.close')"
    centered
    :title="$t('breadcrumb.parleyReport')"
    size="xl"
    :hide-footer="true"
    @hide="isShow = false"
  >
    <div>
      <b-row class="pb-1">
        <b-col md="6">
          {{ $t("field.invoiceNo") }} : {{ data.invoiceNo }}
        </b-col>
        <b-col md="6">
          {{ $t("field.date") }} : {{ data.createdAt | formatDate }}
        </b-col>
        <b-col md="6">
          {{ $t("field.gameType") }} : {{ data.gameType }}
        </b-col>
        <b-col md="6">
          {{ $t("field.type") }} : {{ data.type }}
        </b-col>
        <b-col md="6">
          {{ $t("field.betAmount") }} : {{ data.amount | currency(data.ccy) }}
        </b-col>
        <b-col
          md="6"
          class="text-uppercase"
        >
          {{ $t("field.odd") }} : {{ data.payout }}
        </b-col>
        <b-col
          md="6"
          class="text-uppercase"
        >
          {{ $t("field.estPayoutAmount") }} :
          {{ data.estPayoutAmount | currency(data.ccy) }}
        </b-col>
        <b-col
          md="6"
          class="text-uppercase"
        >
          {{ $t("field.username") }} : {{ data.username }}
        </b-col>
        <b-col
          v-if="data.parleyResult || data.parleyResult == 0"
          md="6"
          class="text-uppercase"
        >
          {{ $t("field.result") }} :
          <span
            :class="
              'text-capitalize text-' + (data.parleyResult ? 'draw' : 'meron')
            "
          >
            {{ data.parleyResult ? $t("general.win") : $t("general.loss") }}
          </span>
        </b-col>
      </b-row>
      <n-table
        :fields="fields"
        :items="items"
        :busy="loading"
        :current-page="1"
      >
        <template #cell(channelId)>
          {{ data.channelId }}
        </template>
        <template #cell(fightMatchNo)="data">
          #{{ data.item.fightMatchNo }}
        </template>
        <template #cell(createdAt)="data">
          {{ data.item.createdAt | formatDate }}
        </template>
        <template #cell(amount)="data">
          {{ data.item.amount | currency(data.item.ccy) }}
        </template>
        <template #cell(selectedBet)="data">
          <span :class="'text-capitalize text-' + data.item.selectedBet">
            {{ data.item.selectedBet }}
          </span>
        </template>
        <template #cell(matchResult)="data">
          <span
            v-if="data.item.matchResult"
            :class="'text-capitalize text-' + data.item.matchResult"
          >
            {{ data.item.matchResult }}
          </span>
        </template>
        <template #cell(wL)="data">
          <span
            v-if="items.length > 1 && data.item.matchResult === 'draw'"
            class="text-capitalize text-draw"
          >
            {{ data.item.matchResult }}
          </span>
          <span
            v-else-if="data.item.matchResult === 'cancel'"
            class="text-capitalize text-cancel"
          >
            {{ data.item.matchResult }}
          </span>
          <span
            v-else-if="data.item.matchResult"
            :class="
              'text-capitalize text-' +
                (data.item.matchResult == data.item.selectedBet
                  ? 'draw'
                  : 'meron')
            "
          >
            {{
              data.item.matchResult == data.item.selectedBet
                ? $t("general.win")
                : $t("general.loss")
            }}
          </span>
        </template>
      </n-table>
    </div>
  </b-modal>
</template>

<script>
import {
  BCol,
  BModal,
  BRow,
} from 'bootstrap-vue'
import NTable from '@/components/NTable.vue'
import tableFields from './tableFields'

export default {
  components: {
    BModal,
    NTable,
    BRow,
    BCol,
  },
  data() {
    return {
      loading: false,
      items: [],
      data: {},
      isShow: false,
    }
  },
  methods: {
    show(data) {
      this.$bvModal.show('modal-parley-detail-report')
      this.isShow = true
      this.items = [...data.bet]
      this.data = { ...data }
    },
    hide() {
      this.$bvModal.hide('modal-parley-detail-report')
      this.items = []
    },
  },
  setup() {
    const fields = [...tableFields]

    return {
      fields,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
